/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #005f98;
  border-color: #005f98;
}
.modal-backdrop.show {
    opacity: 0.07;
}


.bg-dark {
    background-color: #005f98!important;
}
.h1-dark{
  color:#005f98 ;
}