.tamagotchi {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 15px;
  margin-bottom: 10px;
  width: 90%;
  background-color: #f4f4f4;
}

.health {
  background-color: #ff4c4c;
}

.happiness {
  background-color: #4c4cff;
}

.health span,
.happiness span {
  color: white;
  font-weight: bold;
  padding: 0 10px;
}

.health.green {
  background-color: #53c653;
}

.health.yellow {
  background-color: #f1c40f;
}

.happiness.green {
  background-color: #53c653;
}

.happiness.yellow {
  background-color: #f1c40f;
}

.button {
  display: inline-block;
  border-radius: 4px;
  background-color: #4c4cff;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 12px 24px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #5353c6;
}

.tamagotchi-image {
  width: 200px;
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
}

.tamagotchi-age.baby {
  background-image: "/images/baby.png";
}

.tamagotchi-age.toddler {
  background-image: "/images/tamagotchi-baby.png";
}

.tamagotchi-age.teenager {
  background-image: "/images/tamagotchi-baby.png";
}

.tamagotchi-age.adult {
  background-image: "/images/tamagotchi-baby.png";
}

.tamagotchi-age.older {
  background-image: "/images/tamagotchi-baby.png";
}
