.game {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 0 auto; 
  max-width: 1000px;
}

.game {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 0 auto; 
  max-width: 1000px;
}
.board {
  display: flex;
  justify-content: center; 
  padding: 1rem;
  margin: 0 auto; 
  max-width: 1000px;
}

 .garbage-containers {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  } 

  .garbage-container {
    width: 120px;
    height: 50px;
    background-color: #fff;
    border-radius: 10px;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    font-weight: bold;
    color: white;
    font-size: 20px;
  }
  
  .garbage-container:hover {
    transform: translateY(-5px);
  }
  
  .garbage-container h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  
  .garbage-containerList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .garbage-container li {
    display: flex;
    align-items: center;
    margin: 0.5rem;
  }
  
  .garbage-container li img {
    width: 50px;
    height: 50px;
  } 
  
  
  .bin {
    padding: 20px;
    margin: 20px;
    background-color: #f7f7f7;
    border: 2px solid #ccc;
    border-radius: 10px;
    width: 150px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .bin:hover {
    background-color: #ccc;
  }
  
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 80%;
  }
  
  
  .item {
    width: auto;
    height: 90px;
    margin: 5px;
    padding: 2px;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
  }
  .item p {
    padding: 5px;
  }
  
  .item img {
    max-width: 50px;
    max-height: 50px;
  }
  

  .scoreboard {
    display: flex;      
    flex-direction: row;
    align-items: center;
    justify-content:space-evenly;

    background-color: #f9f9f9;
    border-bottom: 2px solid #ccc;
    padding: 1rem;

  }
  
  .score {
    font-size: 2rem;
  }
  
  
  .score span {
    font-size: 80px;
  }
  
  .emoticon {
    font-size: 3rem;
  }
  
  .reset {
    border: none;
    background-color: #ff5252;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .reset:hover {
    transform: translateY(-5px);
  }

  .EndOfGame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    color: yellow;
    text-shadow: 2px 2px 0px black;
    background: linear-gradient(to bottom, #ff8c00, #ff0080);
    padding: 30px;
    border-radius: 10px;
    font-weight: bold;
  }
  